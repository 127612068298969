export const colors12 = [
    "#f5222d", "#fa8c16", "#fadb14", "#52c41a", "#1677ff","#722ed1",
    "#fa541c", "#faad14", "#a0d911", "#13c2c2", "#2f54eb", "#eb2f96"
];
export const colors36 = [
    "#f5222d", "#fa541c", "#fa8c16", "#faad14", "#fadb14", "#a0d911",
    "#52c41a", "#13c2c2", "#1677ff", "#2f54eb", "#722ed1", "#eb2f96",
    "#ff7875", "#ff9c6e", "#ffc069", "#ffd666", "#fff566", "#d3f261",
    "#95de64", "#5cdbd3", "#69b1ff", "#85a5ff", "#b37feb", "#ff85c0",
    "#a8071a", "#ad2102", "#ad4e00", "#ad6800", "#ad8b00", "#5b8c00",
    "#237804", "#006d75", "#003eb3", "#10239e", "#391085", "#9e1068",
  ];