import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import { Typography } from 'antd';
import stravaLoginButton from "../svg/btn_strava_connectwith_orange.svg";
import { ApiUser, getStravaAuthUrl } from '../services/ActivityVizApi';
const { Title, Paragraph } = Typography;

export const Home = () => {
  const [user, setUser] = useLocalStorage<ApiUser|undefined>("user",undefined);
  const navigate = useNavigate();

  if(user) {
    navigate("/activities/table");
  }

  return (
    <div>
      <Title>Activity Viz</Title>
      <Paragraph>Visualize your activities!</Paragraph>
      <a href={getStravaAuthUrl()}>
        <img src={stravaLoginButton} alt="Connect with Strava" />
      </a>
    </div>
  );
};
  
export default Home;