import React, { useState } from "react";
import { FloatButton, Drawer, Form, Select, Slider, Switch, Typography } from "antd";
import { DotChartOutlined, FilterOutlined, ShrinkOutlined, SlidersOutlined } from "@ant-design/icons";
import { months } from "../services/DateTimeUtils";
import { ActivityType, getActivityTypesSelectOptions } from "../services/ActivityTypes";
import { getAxisOptions } from "../services/AxisOptions";
const { Title } = Typography;

interface ChartSettingsProps {
  getParam: (param: string) => any, 
  setParams: (params: Record<string,any>) => void, 
  unit: string,
  agg?: boolean,
  stacked?: boolean,
  xAxis?: Array<string>,
  xDomain?: [number,number],
  yAxis?: Array<string>,
  yDomain?: [number,number],
  colorAxis?: Array<string>,
  filterType?: Array<ActivityType>,
  filterTypes?: Array<ActivityType>,
  filterYear?: Array<number>,
  filterMonth?: boolean
}

export const ChartSettings = (props: ChartSettingsProps) => {
  const { getParam, setParams, unit, agg, stacked, xAxis, xDomain, yAxis, yDomain, colorAxis, filterType, filterTypes, filterMonth, filterYear } = props;
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <FloatButton 
        type="primary" 
        shape="circle" 
        style={{left: 24}}
        icon={<SlidersOutlined />} 
        onClick={() => setDrawerOpen(true)}
      />
      <Drawer title="Chart Settings" placement="right" onClose={() => setDrawerOpen(false)} open={drawerOpen}>
        <Form>
          {
            (filterType || filterTypes || filterYear || filterMonth || xDomain || yDomain) &&
            <Title level={4}><FilterOutlined /> Filters</Title>
          }
          {
            filterType &&
            <Form.Item label="Type">
              <Select
                options={[
                  {label:"All",value: "All"}, 
                  ...getActivityTypesSelectOptions(filterType)
                ]} 
                value={getParam("type")}
                onChange={(type) => setParams({type})}
              />
            </Form.Item>
          }
          {
            filterTypes &&
            <Form.Item label="Types">
              <Select 
                mode="multiple"
                options={getActivityTypesSelectOptions(filterTypes)}
                onChange={(types: Array<string>) => {
                  setParams({types: types.join(",")})
                }}
                value={getParam("types")?.split(",").filter((s: string) => s.length > 0)}
              />
            </Form.Item>
          }
          {
            filterYear &&
            <Form.Item label="Year">
              <Select 
                options={["All", ...filterYear].map(a => {return {label:a,value:a}})} 
                onChange={(year) => setParams({year})}
                value={getParam("year")}
              />
            </Form.Item>
          }
          {
            filterMonth &&
            <Form.Item label="Month">
              <Select 
                defaultValue="All" 
                options={["All", ...months].map(a => {return {label:a,value:a}})} 
                onChange={(month) => setParams({month})}
                value={getParam("month")}
              />
            </Form.Item>
          }
          {
            xDomain &&
            <Form.Item label={`${getParam("xAxis")} Range`}>
              <Slider 
                range
                min={xDomain[0]}
                max={xDomain[1]}
                value={[Number(getParam("xMinSel")),Number(getParam("xMaxSel"))]}
                tooltip={{formatter: (v) => getAxisOptions(getParam("xAxis"), unit).tickFormatter(Number(v))}}
                onChange={(val => {
                  setParams({
                    "xMinSel": val[0],
                    "xMaxSel": val[1]
                  })
                })}
                onAfterChange={(val => {
                  setParams({
                    "xMinSel": val[0],
                    "xMaxSel": val[1]
                  })
                })}
              />
            </Form.Item>
          }
          {
            yDomain &&
            <Form.Item label={`${getParam("yAxis")} Range`}>
              <Slider 
                range
                min={yDomain[0]}
                max={yDomain[1]}
                value={[Number(getParam("yMinSel")),Number(getParam("yMaxSel"))]}
                tooltip={{formatter: (v) => getAxisOptions(getParam("yAxis"), unit).tickFormatter(Number(v))}}
                onChange={(val => {
                  setParams({
                    "yMinSel": val[0],
                    "yMaxSel": val[1]
                  })
                })}
                onAfterChange={(val => {
                  setParams({
                    "yMinSel": val[0],
                    "yMaxSel": val[1]
                  })
                })}
              />
            </Form.Item>
          }
          {
            (xAxis || yAxis || colorAxis) &&
            <Title level={4}><DotChartOutlined /> Axes</Title>
          }
          {
            xAxis &&
            <Form.Item label="X Axis">
              <Select 
                options={xAxis.map(a => {return {label:a,value:a}})} 
                onChange={(xAxis) => setParams({xAxis})}
                value={getParam("xAxis")}
              />
            </Form.Item>
          }
          {
            yAxis &&
            <Form.Item label="Y Axis">
              <Select 
                options={yAxis.map(a => {return {label:a,value:a}})} 
                onChange={(yAxis) => setParams({yAxis})}
                value={getParam("yAxis")}
              />
            </Form.Item>
          }
          {
            colorAxis &&
            <Form.Item label="Color Axis">
              <Select 
                options={colorAxis.map(a => {return {label:a,value:a}})} 
                onChange={(colorAxis) => setParams({colorAxis})}
                value={getParam("colorAxis")}
              />
            </Form.Item>
          }
          {
            stacked &&
            <Form.Item label="Color Stack">
              <Switch 
                checkedChildren="Stacked" 
                unCheckedChildren="Un-Stacked" 
                checked={getParam("stacked") == "true"} 
                onChange={() => setParams({"stacked": getParam("stacked") == "false" ? "true" : "false"})}
              />
            </Form.Item>
          }
          {
            agg &&
            <>
              <Title level={4}><ShrinkOutlined /> Aggregation</Title>
              <Form.Item label="Summarize">
                <Select 
                  options={["Distance", "Elevation", "Moving Time", "Count"].map(a => {return {label:a,value:a}})} 
                  value={getParam("agg")}
                  onChange={(agg) => setParams({agg})}
                />
              </Form.Item>
            </>
          }
        </Form>
      </Drawer>
    </>
  );
}