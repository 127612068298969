import { useMemo } from "react";
import { useLocalStorage } from 'usehooks-ts';
import { ApiUser, Activity, ActivityLocalized, localizeActivity } from "../services/ActivityVizApi";
import { ActivityType, lookupType } from "../services/ActivityTypes";

export const useActivities = () => {
  const [user] = useLocalStorage<ApiUser|undefined>("user",undefined);
  const [activities] = useLocalStorage<Array<Activity>>("activities",[]);

  const activitiesLocalized: ActivityLocalized[] = useMemo(
    () => {
      if(user) {
        return activities
          .map(a => localizeActivity(a,user!.unit));
      } else {
        return [];
      }
    },
    [activities, user?.unit]
  );

  const years: number[] = useMemo(
    () => {
      return Array
      .from(new Set(activitiesLocalized.map(a => new Date(a.startDate).getFullYear())))
      .sort((a,b) => a-b);
    },
    [activities]
  );

  const types: ActivityType[] = useMemo(
    () => {
      return Array
      .from(new Set(activitiesLocalized.map(a => a.type)))
      .map(lookupType)
      .sort((a,b) => a.name.localeCompare(b.name))
      .sort((a,b) => a.category.localeCompare(b.category))
    },
    [activities]
  );

  const typesSpecific: ActivityType[] = useMemo(
    () => {
      return Array
      .from(new Set(activitiesLocalized.map(a => a.typeSpecific)))
      .map(lookupType)
      .sort((a,b) => a.name.localeCompare(b.name))
      .sort((a,b) => a.category.localeCompare(b.category))
    },
    [activities]
  );

  return {
    user,
    activities,
    activitiesLocalized,
    years,
    types,
    typesSpecific
  }
}

