import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useElementSize, useLocalStorage } from 'usehooks-ts';
import { useQuery } from "react-query";
import { ApiUser, getActivity, getActivityStreams, localizeActivity } from "../services/ActivityVizApi";
import { processActivityStreams } from "../services/ActivityStreamUtils";
import { VirtualTable } from "./VirtualTable";

export const ActivityStreamsTable = () => {
  const [contentRef, {height}] = useElementSize();
  const params = useParams();
  const [user, setUser] = useLocalStorage<ApiUser|undefined>("user",undefined);
  const { 
    isLoading: activityIsLoading, 
    //error: activityError, 
    data: activity 
  } = useQuery(["activity", params.activityId],() => getActivity(user!.stravaUserId, Number(params.activityId)));
  const { 
    isLoading: activityStreamIsLoading,
    //error: activityStreamError, 
    data: activityStreams
  } = useQuery(["activityStreams", params.activityId],() => getActivityStreams(user!.stravaUserId,Number(params.activityId)));

  const points: Array<any> = useMemo(() => {
    if(user != undefined && activity != undefined && activityStreams != undefined) {
      const localActivity = localizeActivity(activity, user.unit);
      return processActivityStreams(user, localActivity, activityStreams);
    } else {
      return [];
    }
  },[user, activity, activityStreams]);

  let columns = [
    {
      dataIndex: "time",
      title: "Time",
      render: (v: Date) => v.toLocaleTimeString(),
    },
    {
      dataIndex: "activityTime",
      title: "Activity Time",
    },
    {
      dataIndex: "timeDiff",
      title: "Time Delta",
    },
    {
      dataIndex: "lat",
      title: "Latitude",
      render: (n: any) => n.toFixed(6),
    },
    {
      dataIndex: "lng",
      title: "Longitude",
      render: (n: any) => n.toFixed(6),
    }
  ];
  if(points.length > 0) {
    if(points[0].altitude) {
      columns.push({
        dataIndex: "altitude",
        title: "Altitude",
        render: (n: any) => n.toFixed(0),
      },
      {
        dataIndex: "altitudeDiff",
        title: "Altitude Delta",
        render: (n: any) => n.toFixed(0),
      },
      {
        dataIndex: "altitudeDiff5",
        title: "Altitude Delta 5",
        render: (n: any) => n.toFixed(0),
      },
      {
        dataIndex: "segment",
        title: "Segment",
      });
    }
    if(points[0].distance != undefined) {
      columns.push({
        dataIndex: "distance",
        title: "Distance",
        render: (n: any) => n.toFixed(2),
      });
    }
    if(points[0].heartrate) {
      columns.push({
        dataIndex: "heartrate",
        title: "Heart Rate",
      });
    }
    if(points[0].temp) {
      columns.push({
        dataIndex: "temp",
        title: "Temperature",
      });
    }
    if(points[0].cadence != undefined) {
      columns.push({
        dataIndex: "cadence",
        title: "Cadence",
      });
    }
    if(points[0].watts != undefined) {
      columns.push({
        dataIndex: "watts",
        title: "Watts",
      });
    }
  }

  return (
    <div className="content" ref={contentRef}>
      <VirtualTable 
        dataSource={points} 
        columns={columns}
        pagination={false}
        scroll={{y: height}}
      />
    </div>
  );
}