import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useElementSize } from 'usehooks-ts';
import { 
  Cell,
  Label,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis, 
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import { useStyle } from '../hooks/useStyle';
import { yearsBetween, paceTypes } from "../services/Formatters";
import { AxisOptions, getAxisOptions } from "../services/AxisOptions";
import { ActivityTooltip } from './ActivityTooltip';
import { useActivities } from '../hooks/useActivities';
import { useUrlParams } from '../hooks/useUrlParams';
import { ChartSettings } from './ChartSettings';
import { ActivityLocalized } from '../services/ActivityVizApi';

const minMaxForAxis = (activities: Array<ActivityLocalized>, axis: AxisOptions) => {
  const values = activities.map(a => axis.accessor(a));
  const min = values.reduce((a, b) => { return a < b ? a : b; }); 
  const max = values.reduce((a, b) => { return a > b ? a : b; });
  return [min, max];
}

export const ActivityScatterChart = () => {
  const style = useStyle();
  const navigate = useNavigate();
  const [formRef, { height: formHeight }] = useElementSize();
  const [chartRef, { height: chartHeight }] = useElementSize();
  const { user, activitiesLocalized, types, years } = useActivities();
  const { getParam, setParams } = useUrlParams({
    type: "Run",
    xAxis: "Date",
    yAxis: "Distance",
    xMinSel: 0,
    xMaxSel: 0,
    yMinSel: 0,
    yMaxSel: 0,
  });
  const [xMinNum, setXMinNum] = useState<number>(0);
  const [xMaxNum, setXMaxNum] = useState<number>(0);
  const [yMinNum, setYMinNum] = useState<number>(0);
  const [yMaxNum, setYMaxNum] = useState<number>(0);

  const activitiesFiltered = useMemo(() => {
    if(getParam("type") == "All" || getParam("type") == undefined) {
      return activitiesLocalized;
    } else {
      return activitiesLocalized.filter((a) => a.type === getParam("type"));
    }
  },[activitiesLocalized, getParam("type")]);

  const xAxisOptions = getAxisOptions(getParam("xAxis")!,user!.unit);
  const yAxisOptions = getAxisOptions(getParam("yAxis")!,user!.unit);

  // xAxis change
  useEffect(() => {
    const [xMin, xMax] = minMaxForAxis(activitiesFiltered, xAxisOptions);
    setXMinNum(Number(xMin));
    setXMaxNum(Number(xMax));
    setParams({
      "xMinSel":xMin,
      "xMaxSel":xMax,
    });
  },[getParam("xAxis")]);

  // yAxis change
  useEffect(() => {
    const [yMin, yMax] = minMaxForAxis(activitiesFiltered, yAxisOptions);
    setYMinNum(Number(yMin));
    setYMaxNum(Number(yMax));
    setParams({
      "yMinSel":yMin,
      "yMaxSel":yMax,
    });
  },[getParam("yAxis")]);

  // activity or filter change
  useEffect(() => {
    const [xMin, xMax] = minMaxForAxis(activitiesFiltered, xAxisOptions);
    const [yMin, yMax] = minMaxForAxis(activitiesFiltered, yAxisOptions);

    // if yAxis speed / pace is wrong for new type, override with correct option
    const currentYAxis = getParam("yAxis")!;
    const activityTypeSpeedOrPace = paceTypes.includes(getParam("type")!) ? "Pace" : "Speed";
    const overrideYAxis = ["Pace","Speed"].includes(currentYAxis) && currentYAxis !== activityTypeSpeedOrPace;

    setParams({
      "xMinSel": xMin,
      "xMaxSel": xMax,
      "yAxis": overrideYAxis ? activityTypeSpeedOrPace : currentYAxis,
      "yMinSel": yMin,
      "yMaxSel": yMax,
    });
  },[activitiesFiltered]);
  
  return (
    <div className="content">
      <div ref={formRef}>
        <ChartSettings 
          getParam={getParam}
          setParams={setParams}
          unit={user!.unit}
          xAxis={["Date", "Distance","Elevation", "Moving Time"]}
          xDomain={[xMinNum,xMaxNum]}
          yAxis={["Distance", "Elevation","Moving Time", "Elapsed Time", "Average Heart Rate",paceTypes.includes(getParam("type")!) ? "Pace" : "Speed"]}
          yDomain={[yMinNum,yMaxNum]}
          filterType={types}
        />
      </div>
      <div className="chart" ref={chartRef}>
        <ResponsiveContainer height={chartHeight-formHeight}>
          <ScatterChart>
            <XAxis 
              dataKey={xAxisOptions.dataKey}
              domain={[Number(getParam("xMinSel")),Number(getParam("xMaxSel"))]}
              allowDataOverflow={true}
              //@ts-ignore
              scale={xAxisOptions.scale}
              tickFormatter={xAxisOptions.tickFormatter}
              unit={xAxisOptions.unit}
              type={"number"}
              ticks={xAxisOptions.label == "Date" ? yearsBetween(new Date(Number(getParam("xMinSel"))), new Date(Number(getParam("xMaxSel")))).map(d => d.getTime()) : undefined}
              tick={{fill:style.color}}
              tickLine={{fill:style.color}}
              stroke={style.color}
            >
              <Label value={xAxisOptions.label} fontSize={20} style={{fill:style.color}} />
            </XAxis>
            <YAxis
              dataKey={yAxisOptions.dataKey}
              domain={[Number(getParam("yMinSel")),Number(getParam("yMaxSel"))]}
              axisLine={false}
              allowDataOverflow={true}
              //@ts-ignore
              scale={yAxisOptions.scale}
              tickFormatter={yAxisOptions.tickFormatter}
              unit={yAxisOptions.unit}
              type="number"
              tick={{fill:style.color}}
              tickLine={{fill:style.color}}
              stroke={style.color}
              width={100}
            >
              <Label value={yAxisOptions.label} fontSize={20} style={{fill:style.color}} />
            </YAxis>
            <Scatter 
              data={activitiesFiltered} 
              fill={style.color}
            >
              {
                activitiesFiltered.map((activity, index) => {
                  return (
                    <Cell 
                      key={`cell-${index}`} 
                      onClick={(e) => navigate(`/activities/${activity.stravaActivityId}`)}
                    />
                  );
                })
              }
            </Scatter>
            <Tooltip 
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  return (<ActivityTooltip user={user!} activity={payload[0].payload} style={style} />);
                }}
              }
            />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}