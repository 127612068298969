import React from 'react';
import { Link, Navigate, redirect, useLocation } from "react-router-dom";
import { useQuery } from 'react-query';
import { useLocalStorage, useDarkMode, useWindowSize } from 'usehooks-ts';
import { Button, Menu, Switch } from 'antd';
import type { MenuProps } from 'antd';
import { 
  AppstoreOutlined,
  BarChartOutlined, 
  CloudSyncOutlined, 
  DotChartOutlined,
  HeatMapOutlined, 
  LineChartOutlined, 
  PieChartOutlined,
  SyncOutlined, 
  TableOutlined, 
  UserOutlined 
} from '@ant-design/icons';
import { useStyle } from "../hooks/useStyle";
import { ApiUser, Activity, fetchThenGetActivities, fetchAllActivities } from '../services/ActivityVizApi';
import { routeKeys } from './Router';

const narrowWidth = 640;

export const AppMenu = () => {
  const { isDarkMode, toggle: toggleDarkMode } = useDarkMode();
  const style = useStyle();
  const { width } = useWindowSize();
  const [user, setUser] = useLocalStorage<ApiUser|undefined>("user",undefined);
  const [activities, setActivities] = useLocalStorage<Array<Activity>>("activities",[]);
  const {
    isFetching,
    refetch
  } = useQuery("activities", () => fetchThenGetActivities(user!.stravaUserId,1,15), {
    enabled: !!user,
    onSuccess: setActivities,
    refetchOnWindowFocus: false,
  });
  const location = useLocation();
  const locationKey = routeKeys.get(location.pathname)!;

  // If Logged Out, Redirect to Home
  if((locationKey != "stravaAuthComplete" && locationKey != "home") && user == undefined) {
    return (<Navigate to="/" />);
  }

  const items: MenuProps['items'] = [];
  if(!user) {
    items.push({
      label: <Link to="/">Home</Link>,
      key: 'home',
    });
  }
  if(activities.length > 0) {
    items.push(...[
      {
        label: <Link to="/dashboard">{width > narrowWidth ? "Dashboard" : ""}</Link>,
        key: 'dashboard',
        icon: <Link to="/dashboard"><AppstoreOutlined /></Link>
      },
      {
        label: <Link to="/activities/table">{width > narrowWidth ? "Activities" : ""}</Link>,
        key: 'activitiesTable',
        icon: <Link to="/activities/table"><TableOutlined /></Link>
      },
      {
        label: <Link to="/chart/bar" style={locationKey == "bar" ? undefined : style} >{width > narrowWidth ? "Bar" : ""}</Link>,
        key: "bar",
        icon: <BarChartOutlined />,
        children: [
          {
            label: <Link to="/chart/bar?xAxis=Year">Annual</Link>,
            key: 'annualBar',
          },
          {
            label: <Link to="/chart/bar?xAxis=Month">Monthly</Link>,
            key: 'monthlyBar',
          },
          {
            label: <Link to="/chart/bar?xAxis=Week">Weekly</Link>,
            key: 'weeklyBar',
          },
          {
            label: <Link to="/chart/bar?xAxis=Day">Daily</Link>,
            key: 'dailyBar',
          }
        ]
      },
      {
        label: <Link to="/chart/weekly">{width > narrowWidth ? "Weekly" : ""}</Link>,
        key: 'weeklyChart',
        icon: <Link to="/chart/weekly"><PieChartOutlined /></Link>
      },
      {
        label: <Link to="/chart/scatter">{width > narrowWidth ? "Scatter" : ""}</Link>,
        key: 'activitiesScatter',
        icon: <Link to="/chart/scatter"><DotChartOutlined /></Link>,
      },
      {
        label: <Link to="/chart/ytd">{width > narrowWidth ? "YTD" : ""}</Link>,
        key: 'ytdLine',
        icon: <Link to="/chart/ytd"><LineChartOutlined /></Link>,
      },
      {
        label: <Link to="/heatmap/time">{width > narrowWidth ? "Time Heatmap" : ""}</Link>,
        key: 'timeHeatmap',
        icon: <Link to="/heatmap/time"><HeatMapOutlined /></Link>
      }
    ]);
  }
  if(user){
    const toggleUnit = () => {
      setUser({
        ...user,
        unit: user.unit == 'metric' ? 'imperial' : 'metric'
      });
    }
    const logOut = () => {
      setUser(undefined);
      setActivities([]);
    }
    items.push(
    {
      key: "userMenu",
      icon: <UserOutlined />,
      label: isFetching && <SyncOutlined spin={isFetching} />,
      children: [
        {
          label: `${user.username}`,
          key: "username",
          disabled: true,
        },
        {
          label: "Sync",
          key: "sync",
          icon: <SyncOutlined spin={isFetching} />,
          onClick: () => refetch()
        },
        {
          label: "Fetch All",
          key: "fetchAll",
          icon: <CloudSyncOutlined />,
          onClick: () => fetchAllActivities(user.stravaUserId).then(r => console.log(r))
        },
        {
          key: "unit",
          label: (
            <Switch 
              checkedChildren="Metric" 
              unCheckedChildren="Imperial" 
              checked={user.unit == "metric"} 
              onChange={toggleUnit}
            />
          ),
          onClick: toggleUnit
        },
        {
          key: "darkMode",
          label: (
            <Switch 
              checkedChildren="Dark" 
              unCheckedChildren="Light" 
              checked={isDarkMode} 
              onChange={toggleDarkMode}
            />
          ),
          onClick: toggleDarkMode
        },
        {
          key: "logOut",
          label: (
            <Button 
              type="primary" 
              danger
              onClick={logOut}
            >
              Log Out
            </Button>
          ),
          onClick: logOut
        }
      ]
    })
  }

  return (
    <Menu 
      mode="horizontal" 
      items={items} 
      selectedKeys={[locationKey]} 
    />
  );
}