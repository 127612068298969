import React from "react";
import { lookupType } from "../services/ActivityTypes";
import { Tag } from 'antd';

interface ActivityTypeTagProps {
  type: string;
};

export const ActivityTypeTag = (props: ActivityTypeTagProps) => {
  const type = lookupType(props.type);
  return (
    <Tag color={type.color} key={type.key}>
      {type.name}
    </Tag>
  );
};

export default ActivityTypeTag;