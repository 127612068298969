import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { useElementSize } from 'usehooks-ts';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useActivities } from "../hooks/useActivities";
import { useStyle } from '../hooks/useStyle';
import { useUrlParams } from "../hooks/useUrlParams";
import { formatCommas } from "../services/Formatters";
import { months, msPerDay } from "../services/DateTimeUtils";
import { colors36 } from "../services/Colors";
import { groupBy } from "../services/General";
import { getAggOptions } from "../services/GroupAxisOptions"
import { ChartSettings } from "./ChartSettings";

export const YtdLineChart = () => {
  const style = useStyle();
  const [ref, { height }] = useElementSize();
  const { user, activitiesLocalized, types } = useActivities();
  const { getParam, setParams } = useUrlParams({
    agg: "Distance",
    type: "Run"
  });

  const aggOptions = getAggOptions(getParam("agg")!,user!.unit);

  let activitiesFiltered = activitiesLocalized;
  if(getParam("type") != "All") {
    activitiesFiltered = activitiesFiltered.filter((a) => a.type === getParam("type"));
  }
  activitiesFiltered = activitiesFiltered.sort((a,b) => a.startDateLocal.getTime() - b.startDateLocal.getTime());
  const activitiesByYear = groupBy(activitiesFiltered, (a) => {return {year: a.startDateLocal.getFullYear()}});
  const yearKeys = Array.from(activitiesByYear.keys());
  const yearNums: Number[] = yearKeys.map(yk => JSON.parse(yk)['year']);
  let yearStrings: string[] = yearNums.map(yn => String(yn));

  let data: Record<string,any>[] = [];
  const annualActivityIndex = new Array(yearNums.length).fill(0);
  const annualCumSum = new Array(yearNums.length).fill(0);
  const baseDate = new Date("2000-01-01T23:59:59");
  // for each day in the year
  for(let d=0; d<366; d++) {
    data[d] = {
      date: new Date(baseDate),
    }
    // for each year 
    for(let y=0; y< yearNums.length; y++) {
      const yearDate = new Date(baseDate);
      yearDate.setFullYear(Number(yearNums[y]));
      const activitiesForYear = activitiesByYear.get(yearKeys[y])!;
      // add all activities before current date
      while(annualActivityIndex[y]<activitiesForYear.length && yearDate.getTime() > new Date(activitiesForYear[annualActivityIndex[y]].startDateLocal).getTime()) {
        annualCumSum[y] += aggOptions.accessor(activitiesForYear[annualActivityIndex[y]]);
        annualActivityIndex[y]++;
      }
      data[d][yearStrings[y]] = annualCumSum[y];
    }
    baseDate.setTime(baseDate.getTime() + msPerDay);
  }

  const axisDates = data.map(d => d.date).filter((d: Date) => d.getDate() == 1);

  if(!user || activitiesLocalized.length == 0) {
    // no user?  redirect to home
    return (<Navigate to="/" />)
  } else {
    return (
      <div className="content">
        <ChartSettings 
          getParam={getParam}
          setParams={setParams}
          unit={user!.unit}
          agg={true}
          filterType={types}
        />
        <div className="chart" ref={ref}>
          <ResponsiveContainer height={height}>
            <LineChart data={data} style={style}>
              <XAxis 
                dataKey="date" 
                tickFormatter={(d: Date) => `${months[d.getMonth()]} ${d.getDate()}`}
                ticks={axisDates}
                tick={{fill:style.color}}
                tickLine={{fill:style.color}}
                stroke={style.color}
              />
              <YAxis 
                unit={` ${aggOptions.unit}`}
                axisLine={false} // don't show y axis line
                tick={{fill:style.color}}
                tickLine={{fill:style.color}}
                tickFormatter={aggOptions.formatter}
                stroke={style.color}
                width={90}
              />
              <Tooltip 
                contentStyle={style}
                labelFormatter={(d: Date) => `${months[d.getMonth()]} ${d.getDate()}`}
                labelStyle={{fontWeight: 'bold'}}
                //@ts-ignore
                formatter={aggOptions.formatter}
              />
              {
                yearStrings.map((y,i) => (
                  <Line 
                    type="stepAfter" 
                    dataKey={y}
                    dot={false} 
                    stroke={colors36[i%colors36.length]}
                  />
                ))
              } 
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
};

export default YtdLineChart;