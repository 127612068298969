import React, { useMemo } from "react";
import { renderToString } from 'react-dom/server'
import { useNavigate } from "react-router-dom";
import { useElementSize } from 'usehooks-ts';
import { arc, axisLeft, axisTop, pie, scaleBand, scaleOrdinal, scalePow, select } from "d3";
import { useActivities } from "../hooks/useActivities";
import { useStyle } from "../hooks/useStyle";
import { useUrlParams } from "../hooks/useUrlParams";
import { ActivityLocalized } from "../services/ActivityVizApi";
import { days, getDaysStartingWith, weekOfYear } from "../services/DateTimeUtils";
import { groupByAgg, max, sum } from "../services/General";
import { ActivityTooltip } from "./ActivityTooltip";
import { ChartSettings } from "./ChartSettings";
import { getAggOptions } from "../services/GroupAxisOptions";

export const WeeklyChart = () => {
  const style = useStyle();
  const { user, activitiesLocalized, types } = useActivities();
  const { getParam, setParams } = useUrlParams({
    agg:"Distance"
  });
  const [ref, { width }] = useElementSize();
  const navigate = useNavigate();

  if(!user || activitiesLocalized.length == 0) {
    navigate("/");
  }

  const aggOptions = getAggOptions(getParam("agg")!,user!.unit)

  const firstDayOfWeek = "Monday";

  const chart = useMemo(() => {
    const localActivities = activitiesLocalized
      .map(a => {
        return {
          ...a,
          "week": weekOfYear(a.startDate,firstDayOfWeek).yearWeek,
          "day": days[a.startDate.getDay()],
          "count": 1
        }
      });

    const chartData: any[] = groupByAgg(
      localActivities,
      (a) => {
        return {
          "week": a.week,
          "day": a.day
        }
      },
      (arr) => {
        return {
          "values": arr,
          "agg": aggOptions.accumulator(arr)
        }
      }
    );

    const weeks = Array.from(new Set(localActivities.map(d => d.week))).sort().reverse();
    let daysAligned = getDaysStartingWith(firstDayOfWeek);
    const daysLabels = width > 640 ? daysAligned : daysAligned.map(d => d.substring(0,3));

    const margin = {top: 30, right: 20, bottom: 20, left: 75};
    const chartWidth = width - margin.left - margin.right;
    const cellSize = Math.max(chartWidth / 7,0);
    const height = cellSize * weeks.length;

    // clear current content
    select("#weeklyPies").html("");
    select("#topAxis").html("");

    // append the svg object to the body of the page
    let topSvg = select("#topAxis")
      .append("svg")
        .attr("width", width)
        .attr("height", margin.top)
      .append("g")
        .attr("transform",`translate(${margin.left},${margin.top})`); //
    let chartSvg = select("#weeklyPies")
      .append("svg")
        .attr("width", width)
        .attr("height", height)
      .append("g")
        .attr("transform",`translate(${margin.left},0)`); //${margin.top}

    // X Scale & Axis
    let xScale = scaleBand()
      .range([ 0, chartWidth ])
      .domain(daysAligned)
      .padding(0.01);
    topSvg.append("g")
      .style("font-size", "1em")
      .call(axisTop(xScale)
        //@ts-ignore
        .tickFormat((d,i) => daysLabels[i])
      )
      .select(".domain").remove(); // don't show x axis line

    // Y Scale & Axis
    var yScale = scaleBand()
      .range([ 0, height ])
      .domain(weeks)
      .padding(0.01);
    chartSvg.append("g")
      .style("font-size", "1em")
      .call(axisLeft(yScale))
      .select(".domain").remove(); // don't show y axis line

    // R Scale
    const maxValue = chartData.map(d => d.agg).reduce(max,0);
    let rScale = scalePow()
      .exponent(.25)
      .domain([0,maxValue])
      .range([0,cellSize/2]);

    // Color Scale
    let colorScale = scaleOrdinal()
      .domain(types.map(t => t.key))
      .range(types.map(t => t.color));

    let tooltip = select("#tooltip")
      .style("opacity", 0);

    var mouseover = (e: any) => {
      tooltip.style("opacity", 1);
    }
    const mousemove = (e: any) => {
      const activity: ActivityLocalized = e.target.__data__.data;
      const tooltipElement = (<ActivityTooltip user={user!} activity={activity} style={style} />);
      const htmlString = renderToString(tooltipElement);
      const offset = 10;
      const tooltipWidth = 320;
      const x = e.x + tooltipWidth + offset < width ? e.x + offset : e.x - (tooltipWidth + offset);
      tooltip
        .html(htmlString)
        .style("position","absolute")
        .style("left", `${x}px`)
        .style("top", `${e.y + offset}px`);
    }
    const mouseleave = (e: any) => {
      tooltip.style("opacity", 0)
        .style("left", "-400px")
        .style("top", "-400px");
    }
    const onclick = (e: any) => {
      const activity: ActivityLocalized = e.target.__data__.data;
      navigate(`/activities/${activity.stravaActivityId}`)
    }

    const innerRadius = 0;
    for(let i=0; i<chartData.length; i++) {
      let day = chartData[i];
      let g = chartSvg.append("g")
        .attr("transform",`translate(${Number(xScale(day.day)) + cellSize/2},${Number(yScale(day.week)) + cellSize/2})`);
      let chartPie = pie()
        .value((v: any) => aggOptions.accumulator([v]));
      const outerRadius: number = rScale(day.agg);
      let chartArc = arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius);

      let arcs = g.selectAll("arc")
        .data(chartPie(day.values))
        .enter()
        .append("g")
        .on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseleave", mouseleave)
        .on("click",onclick);

      arcs.append("path")
        //@ts-ignore
        .attr("fill",(d: any) => colorScale(d.data.type))
        //@ts-ignore
        .attr("d", chartArc);
    }
  }, [activitiesLocalized, width, getParam("agg")])

  return (
    <div className="content" ref={ref}>
      <ChartSettings 
        getParam={getParam}
        setParams={setParams}
        unit={user!.unit}
        agg={true}
      />
      <div id="topAxis" />
      <div className="scrollable">
        <div id="weeklyPies" />
        <div id="tooltip" />
      </div>
    </div>
  );
}

export default WeeklyChart;