import { Activity, ActivityLocalized } from "./ActivityVizApi";
import { ActivityTypeTag } from "../components/ActivityTypeTag";
import { groupBy } from "./General";

export type ActivityType = {
  key: string;
  name: string;
  category: string;
  color: string;
}

export const types: Array<ActivityType> = [
  {
    key: "AlpineSki",
    name: "Alpine Ski",
    category: "Winter",
    color: "#95de64"
  },
  {
    key: "BackcountrySki",
    name: "Backcountry Ski",
    category: "Winter",
    color: "#52c41a"
  },
  {
    key: "Canoeing",
    name: "Canoeing",
    category: "Water",
    color: "#ffe7ba"
  },
  {
    key: "Crossfit",
    name: "Crossfit",
    category: "Workout",
    color: "#d3adf7"
  },
  {
    key: "EBikeRide",
    name: "E-Bike Ride",
    category: "Bike",
    color: "#bae0ff"
  },
  {
    key: "Elliptical",
    name: "Elliptical",
    category: "Workout",
    color: "#b37feb"
  },
  {
    key: "EMountainBikeRide",
    name: "Electric Mountain Bike Ride",
    category: "Bike",
    color: "#91caff"
  },
  {
    key: "Golf",
    name: "Golf",
    category: "Golf",
    color: "#faad14"
  },
  {
    key: "GravelRide",
    name: "Gravel Bike Ride",
    category: "Bike",
    color: "#69b1ff"
  },
  {
    key: "Handcycle",
    name: "Handcycle",
    category: "Bike",
    color: "#4096ff"
  },
  {
    key: "Hike",
    name: "Hike",
    category: "Feet",
    color: "#ff7875"
  },
  {
    key: "IceSkate",
    name: "Ice Skate",
    category: "Winter",
    color: "#237804"
  },
  {
    key: "InlineSkate",
    name: "Inline Skate",
    category: "Roll",
    color: "#08979c"
  },
  {
    key: "Kayaking",
    name: "Kayaking",
    category: "Water",
    color: "#ffd591"
  },
  {
    key: "Kitesurf",
    name: "Kitesurf",
    category: "Water",
    color: "#ffc069",
  },
  {
    key: "MountainBikeRide",
    name: "Mountain Bike Ride",
    category: "Bike",
    color: "#1677ff"
  },
  {
    key: "NordicSki",
    name: "Nordic Ski",
    category: "Winter",
    color: "#389e0d"
  },
  {
    key: "Ride",
    name: "Bike",
    category: "Bike",
    color: "#0958d9"
  },
  {
    key: "RockClimbing",
    name: "Rock Climbing",
    category: "Workout",
    color: "#b37feb"
  },
  {
    key: "RollerSki",
    name: "Roller Ski",
    category: "Roll",
    color: "#13c2c2"
  },
  {
    key: "Rowing",
    name: "Rowing",
    category: "Water",
    color: "#ffa940"
  },
  {
    key: "Run",
    name: "Run",
    category: "Feet",
    color: "#f5222d"
  },
  {
    key: "Sail",
    name: "Sail",
    category: "Water",
    color: "#d46b08"
  },
  {
    key: "Skateboard",
    name: "Skateboard",
    category: "Roll",
    color: "#36cfc9"
  },
  {
    key: "Snowboard",
    name: "Snowboard",
    category: "Winter",
    color: "#73d13d"
  },
  {
    key: "Snowshoe",
    name: "Snowshoe",
    category: "Winter",
    color: "#237804"
  },
  {
    key: "Soccer",
    name: "Soccer",
    category: "Feet",
    color: "#a8071a"
  },
  {
    key: "StairStepper",
    name: "Stair Stepper",
    category: "Workout",
    color: "#9254de"
  },
  {
    key: "StandUpPaddling",
    name: "Stand Up Paddling",
    category: "Water",
    color: "#ad4e00"
  },
  {
    key: "Surfing",
    name: "Surfing",
    category: "Water",
    color: "#873800"
  },
  {
    key: "Swim",
    name: "Swim",
    category: "Water",
    color: "#fa8c16"
  },
  {
    key: "TrailRun",
    name: "Trail Run",
    category: "Feet",
    color: "#ff4d4f"
  },
  {
    key: "Velomobile",
    name: "Velomobile",
    category: "Bike",
    color: "#003eb3"
  },
  {
    key: "VirtualRide",
    name: "Virtual Ride",
    category: "Bike",
    color: "#002c8c"
  },
  {
    key: "VirtualRun",
    name: "Virtual Run",
    category: "Feet",
    color: "#cf1322"
  },
  {
    key: "Walk",
    name: "Walk",
    category: "Feet",
    color: "#ffa39e"
  },
  {
    key: "WeightTraining",
    name: "Weight Training",
    category: "Workout",
    color: "#722ed1"
  },
  {
    key: "Wheelchair",
    name: "Wheelchair",
    category: "Roll",
    color: "#5cdbd3"
  },
  {
    key: "Workout",
    name: "Workout",
    category: "Workout",
    color: "#531dab"
  },
  {
    key: "Yoga",
    name: "Yoga",
    category: "Workout",
    color: "#391085"
  },
];

const typeMap: Map<string,ActivityType> = new Map<string,ActivityType>();
for(let i=0; i<types.length; i++)
  typeMap.set(types[i].key,types[i]);

export const lookupType = (key: string): ActivityType => {
  const foundType = typeMap.get(key);
  if(foundType)
    return foundType;
  else
    return {
      key,
      name: key,
      category: 'Unknown',
      color: 'grey'
    }
}

export const getActivityTypesSelectOptions = (types: Array<ActivityType>) => {
  const typesGrouped = groupBy(types,(t) => {return {"category":t.category}});
  const typeSelectOptions = Array.from(typesGrouped.entries())
    .map(([g,vals]) => {
      return {
        label: JSON.parse(g).category,
        options: vals.map(t => {
          return {
            label: ActivityTypeTag({type:t.key}),
            value: t.key
          }
        })
      }
    });
  return typeSelectOptions;
}