import { DataFrame, Series } from "data-forge";
import { ApiUser, Activity, ActivityLocalized, ActivityStreams } from "./ActivityVizApi";
import { localizeDistance, localizePace, localizeSpeed, localizeTemp, localDistanceUnit, localSpeedUnit, localTempUnit } from "./Formatters";
import { interpolateViridis, interpolateRdBu, interpolateSinebow } from "d3"

export type ActivityStreamPoint = {
  time: Date;
  activityTime: number;
  timeDiff: number;
  lat?: number;
  lng?: number;
  altitude?: number;
  distance?: number;
  speed?: number;
  heartrate?: number;
  temp?: number;
  cadence?: number;
  watts?: number;
  grade?: number;
} 

export const processActivityStreams = (user: ApiUser, activity: ActivityLocalized, activityStreams: ActivityStreams): Array<any> => {
  const activityStartTime: number = activity.startDate.getTime();
  const columns: any = {};
  if(activityStreams.time) {
    columns['activityTime'] = new Series(activityStreams.time.data);
    columns['timeDiff'] = [0,...new Series(activityStreams.time.data).rollingWindow(2).select(pair => (pair.last() - pair.first())).toArray()];
    columns['time'] = new Series(activityStreams.time.data.map(t => new Date(activityStartTime + (t * 1000))));
  }
  if(activityStreams.latlng) {
    columns['lat'] = new Series(activityStreams.latlng.data.map(l => l[0]));
    columns['lng'] = new Series(activityStreams.latlng.data.map(l => l[1]));
  }
  if(activityStreams.altitude) {
    const altitudeSeries = new Series(activityStreams.altitude.data.map(a => localizeDistance(a,user!.unit,false)))
    columns['altitude'] = altitudeSeries;
    columns['altitudeDiff'] = [0,...altitudeSeries.rollingWindow(2).select(pair => (pair.last() - pair.first())).toArray()];
    const altitudeDiff5 = [0,0,0,0,...altitudeSeries.rollingWindow(5).select(pair => (pair.last() - pair.first())).toArray()];
    columns['altitudeDiff5'] = altitudeDiff5
    columns['segment'] = altitudeDiff5.map(v => v > 5 ? "Climb" : v < -5 ? "Descend" : "Flat");
  }
  if(activityStreams.distance)
    columns['distance'] = new Series(activityStreams.distance.data.map(d => localizeDistance(d,user!.unit,true)));
  if(activityStreams.velocity_smooth) {
    columns['speed'] = new Series(activityStreams.velocity_smooth.data.map(v => localizeSpeed(v,user!.unit)))
    columns['pace'] = new Series(activityStreams.velocity_smooth.data.map(v => localizePace(v,user!.unit)))
  }
    
  if(activityStreams.heartrate)
    columns['heartrate'] = new Series(activityStreams.heartrate.data);
  if(activityStreams.cadence)
    columns['cadence'] = new Series(activityStreams.cadence.data);
  if(activityStreams.temp)
    columns['temp'] = new Series(activityStreams.temp.data.map(t => localizeTemp(t,user.unit)));
  if(activityStreams.watts)
    columns['watts'] = new Series(activityStreams.watts.data);
  if(activityStreams.grade_smooth)
    columns['grade'] = new Series(activityStreams.grade_smooth.data);
  const points = new DataFrame({columns});
  return points.toArray();
}

export const splitPoints = (points: Array<ActivityStreamPoint>) => {
  const distanceSplits = [];
  let distanceSplit = {
    name: 1,
    start: 0,
    end: 0
  }
  for(let i=0; i<points.length; i++) {
    if(points[i].distance! >= distanceSplit.name) {
      distanceSplit.end = i;
      distanceSplits.push(distanceSplit);
      distanceSplit = {
        name: distanceSplit.name + 1,
        start: i,
        end: 0
      }
    }
  }
  distanceSplit.end = points.length-1;
  distanceSplits.push(distanceSplit);
  return {
    distanceSplits
  }
}

export const summarizeLatLngs = (latLngs: [number, number][]) => {
  let minLat = 999, maxLat = -999, minLng = 999, maxLng = -999;
  latLngs.forEach(v => {
    const lat = v[0], lng = v[1];
    if(lat < minLat)
      minLat = lat;
    if(lat > maxLat)
      maxLat = lat;
    if(lng < minLng)
      minLng = lng;
    if(lng > maxLng)
      maxLng = lng;
  });
  const dLat = maxLat - minLat;
  const dLng = maxLng - minLng;
  const centLat = minLat + (dLat / 2);
  const centLng = minLng + (dLng / 2);
  return {
    minLat, maxLat, minLng, maxLng,
    dLat, dLng, centLat, centLng
  }
}

export type ActivityStreamAccessor = {
  name: string;
  accessor: (streams: ActivityStreams) => any[];
  unit: string;
}

export const indexStreamAccessor = {
  name: "Time",
  accessor: (streams: ActivityStreams) => streams.time!.data,
  unit: "Seconds"
}

export const altitudeStreamAccessor = (unit: string) => {
  return {
    name: "Altitude",
    accessor: (streams: ActivityStreams) => streams.altitude!.data,
    unit: localDistanceUnit(unit,false)
  }
}

export const heartrateStreamAccessor = {
  name: "Heart Rate",
  accessor: (streams: ActivityStreams) => streams.heartrate!.data,
  unit: "bpm"
}

export const velocityStreamAccessor = (unit: string) => {
  return {
    name: "Speed",
    accessor: (streams: ActivityStreams) => streams.velocity_smooth!.data,
    unit: localSpeedUnit(unit)
  }
}

export const distanceStreamAccessor = (unit: string) => {
  return {
    name: "Distance",
    accessor: (streams: ActivityStreams) => streams.distance!.data,
    unit: localDistanceUnit(unit,true)
  }
}

export const gradeStreamAccessor = {
  name: "Grade",
  accessor: (streams: ActivityStreams) => streams.grade_smooth!.data,
  unit: "%"
}

export const tempStreamAccessor = {
  name: "Temp",
  accessor: (streams: ActivityStreams) => streams.temp!.data,
  unit: "degrees"
}

export const wattsStreamAccessor = {
  name: "Watts",
  accessor: (streams: ActivityStreams) => streams.grade_smooth!.data,
  unit: "w"
}

export const getStreamAccessor = (agg: string, unit: string) : ActivityStreamAccessor => {
  switch(agg) {
    case "Altitude":
      return altitudeStreamAccessor(unit);
    case "Heart Rate":
      return heartrateStreamAccessor;
    case "Speed":
      return velocityStreamAccessor(unit);
    case "Distance":
      return distanceStreamAccessor(unit);
    case "Temp":
      return tempStreamAccessor;
    case "Grade":
      return gradeStreamAccessor;
    case "Watts":
      return wattsStreamAccessor;
    default:
      return indexStreamAccessor;
  }
}

export const getColorScheme = (scale: string) => {
  switch(scale) {
    case "Viridis":
      return interpolateViridis;
    case "Red Blue":
      return interpolateRdBu;
    case "Rainbow":
      return interpolateSinebow;
    default: 
      return interpolateViridis;
  }
}