import React from 'react';
import { Card, Col, Row, Statistic } from 'antd';
import { useActivities } from '../hooks/useActivities';
import GroupedPieChart from './GroupedPieChart';
import { useNavigate } from 'react-router-dom';
import WeeklyChart from './WeeklyChart';


export const Dashboard = () => {
  const navigate = useNavigate();
  const { user, activitiesLocalized, types, typesSpecific, years } = useActivities();

  return (
    <>
      <Row gutter={16}>
        <Col span={6}>
          <Card style={{margin:"1em"}}>
            <Statistic 
              title="Activities" 
              value={activitiesLocalized.length} 
              valueStyle={{fontSize:"4em"}}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card style={{margin:"1em"}}>
            <Statistic 
              title="Activity Types" 
              value={types.length} 
              valueStyle={{fontSize:"4em"}}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card style={{margin:"1em"}}>
            <Statistic 
              title="Years" 
              value={years.length} 
              valueStyle={{fontSize:"4em"}}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card style={{margin:"1em"}}>
            <Statistic 
              title="City" 
              value={user?.city} 
              valueStyle={{fontSize:"4em"}}
            />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Card 
            style={{margin:"1em"}} 
            bodyStyle={{height:"300px"}}
            onClick={() => navigate("/chart/pie")}
          >
            <GroupedPieChart />
          </Card>
        </Col>
        <Col span={12}>
          <Card 
            style={{margin:"1em"}} 
            bodyStyle={{height:"300px"}}
            onClick={() => navigate("/chart/weekly")}
          >
            <WeeklyChart />
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Dashboard;