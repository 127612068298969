import React, { useEffect } from "react";
import { Navigate, redirect, useSearchParams } from "react-router-dom";
import { useLocalStorage } from 'usehooks-ts';
import { Activity, completeStravaAuth, getActivities } from '../services/ActivityVizApi';
import { ApiUser } from '../services/ActivityVizApi';
import { Spin, Steps } from 'antd';
import { FireOutlined, KeyOutlined, LoadingOutlined, UserOutlined } from '@ant-design/icons';

export const StravaAuthComplete = () => {
  const [searchParams] = useSearchParams();
  const [user, setUser] = useLocalStorage<ApiUser|undefined>("user",undefined);
  const [activities, setActivities] = useLocalStorage<Array<Activity>>("activities",[]);

  const code = searchParams.get("code")!;

  useEffect(() => {
    if(!user) {
      completeStravaAuth(code)
      .then((resUser) => {
        setUser(resUser);
        return getActivities(resUser.stravaUserId);
      })
      .then((resActivities) => {
        setActivities(resActivities);
      });
    }
  },[]);

  if(activities && activities.length > 0) {
    return (<Navigate to="/activities/table" />);
  }

  return (
    <Steps
      direction="vertical"
      items={[
        {
          title: "Authorization",
          status: "finish",
          icon: <KeyOutlined />
        },
        {
          title: "User",
          status: user ? "finish" : "process",
          icon: user ? <UserOutlined /> : <LoadingOutlined />
        },
        {
          title: "Activities",
          status: activities && activities.length > 0 ? "finish" : "process",
          icon: activities && activities.length > 0 ? <FireOutlined /> : <LoadingOutlined />
        },
      ]}
    />
  );
};

export default StravaAuthComplete;
