import { ActivityLocalized } from "./ActivityVizApi";
import { scaleLinear, scaleLog } from "d3";
import { formatSeconds, localDistanceUnit, localPaceUnit, localSpeedUnit } from "./Formatters";

export interface AxisOptions {
  accessor: (activity: ActivityLocalized) => string | number;
  dataKey: string;
  label: string;
  scale: any;
  tickFormatter: (a: any) => any;
  unit: string;
}

export const dateAxisOptions = {
  "accessor": (a: ActivityLocalized) => a.startDate.getTime(),
  "dataKey": "startDate",
  "label": "Date",
  "scale": scaleLinear(),
  "tickFormatter": (n: number) => (new Date(n)).toLocaleDateString(),
  "unit": "",
}
  
export const movingTimeAxisOptions = {
  "accessor": (a: ActivityLocalized) => a.movingTime,
  "dataKey": "movingTime",
  "label": "Moving Time",
  "scale": scaleLinear(),
  "tickFormatter": (n: number) => formatSeconds(n),
  "unit": "",
}

export const averageHeartRateAxisOptions = {
  "accessor": (a: ActivityLocalized) => a.avgHeartrate || 0,
  "dataKey": "avgHeartrate",
  "label": "Average Heart Rate",
  "scale": scaleLinear(),
  "tickFormatter": (n: number) => n.toFixed(0),
  "unit": "bpm",
}
  
export const elapsedTimeAxisOptions = {
  "accessor": (a: ActivityLocalized) => a.elapsedTime,
  "dataKey": "elapsedTime",
  "label": "Elapsed Time",
  "scale": scaleLinear(),
  "tickFormatter": (n: number) => formatSeconds(n),
  "unit": "",
}

export const distanceAxisOptions = (unit: string) => {
  return {
    "accessor": (a: ActivityLocalized) => a.distance,
    "dataKey": "distance",
    "label": "Distance",
    "scale": scaleLinear(),
    "tickFormatter": (n: number) => n.toFixed(1),
    "unit": localDistanceUnit(unit,true),
  }
}

export const elevationAxisOptions = (unit: string) => {
  return {
    "accessor": (a: ActivityLocalized) => a.elevationGain,
    "dataKey": "elevationGain",
    "label": "Elevation Gain",
    "scale": scaleLinear(),
    "tickFormatter": (n: any) => n.toFixed(0),
    "unit": localDistanceUnit(unit,false),
  }
}

export const speedAxisOptions = (unit: string) => {
  return {
    "accessor": (a: ActivityLocalized) => a.speed,
    "dataKey": "speed",
    "label": "Speed",
    "scale": scaleLinear(),
    "tickFormatter": (n: number) => n.toFixed(1),
    "unit": localSpeedUnit(unit),
  }
}

export const paceAxisOptions = (unit: string) => {
  return {
    "accessor": (a: ActivityLocalized) => a.pace,
    "dataKey": "pace",
    "label": "Pace",
    "scale": scaleLog().base(30), //scaleSqrt().exponent(.1)
    "tickFormatter": (v: any) => formatSeconds(v),
    "unit": localPaceUnit(unit),
  }
}

export const getAxisOptions = (axisName: string, unit: string): AxisOptions => {
  switch(axisName) {
    case "Date":
      return dateAxisOptions;
    case "Average Heart Rate":
      return averageHeartRateAxisOptions;
    case "Moving Time":
      return movingTimeAxisOptions;
    case "Elapsed Time":
      return elapsedTimeAxisOptions;
    case "Distance":
      return distanceAxisOptions(unit);
    case "Elevation":
      return elevationAxisOptions(unit);
    case "Speed":
      return speedAxisOptions(unit);
    case "Pace":
      return paceAxisOptions(unit);
    default:
      return dateAxisOptions;
  }
}