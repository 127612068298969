import React from 'react';
import { Form, Col, Row, Statistic, Select, Typography } from 'antd';
import { ApiUser, ActivityLocalized } from "../services/ActivityVizApi";
import { formatSeconds, localDistanceUnit, localPaceUnit, localSpeedUnit, paceTypes } from "../services/Formatters";
const { Title } = Typography;

interface ActivityTooltipProps {
  activity: ActivityLocalized;
  user: ApiUser;
  style?: any;
}

export const ActivityTooltip = (props: ActivityTooltipProps) => {
  const { activity, user, style } = props;
  const titleColor = style.color === "#fff" ? "rgba(255,255,255,0.45)" : "rgba(0,0,0,0.45)";
  const StatTitle = (props: {title: string}) => {
    return (
      <div style={{color:titleColor}}>{props.title}</div>
    );
  }

  return (
    <div 
      style={
        {
          ...style, 
          padding: "1em",
          border: `1px solid ${style?.color}`, 
          width: "320px"
        }
      }
    >
      <Title level={2} style={{...style, marginTop: 0}}>{activity.name}</Title>
      <Row>
        <Col span="12">
          <Statistic 
            title={<StatTitle title="Date" />}
            value={activity.startDate.toLocaleDateString()}
            style={style}
            valueStyle={style}
          />
        </Col>
        <Col span="12">
          <Statistic 
            title={<StatTitle title="Distance" />}
            value={activity.distance}
            precision={1}
            suffix={localDistanceUnit(user.unit,true)}
            valueStyle={style}
          />
        </Col>
      </Row>
      <Row>
        <Col span="12">
          <Statistic 
            title={<StatTitle title="Elevation Gain" />}
            value={activity.elevationGain}
            precision={0}
            groupSeparator=","
            suffix={localDistanceUnit(user.unit,false)}
            valueStyle={style}
          />
        </Col>
        <Col span="12">
        {
          paceTypes.includes(activity.type) ?
          <Statistic 
            title={<StatTitle title="Average Pace" />}
            value={formatSeconds(activity.pace)}
            suffix={localPaceUnit(user.unit)}
            valueStyle={style}
          />
          :
          <Statistic 
            title={<StatTitle title="Average Speed" />}
            value={activity.speed}
            precision={1}
            suffix={localSpeedUnit(user.unit)}
            valueStyle={style}
          />
        }
        </Col>
      </Row>
    </div>
  );
}