import React from "react";
import { useElementSize } from "usehooks-ts";
import { ActivityTypeTag } from "./ActivityTypeTag";
import { types } from "../services/ActivityTypes";
import { VirtualTable } from "./VirtualTable";

export const ActivityTypesTable = () => {
  const [contentRef, {height}] = useElementSize();
  return (
    <div className="content" ref={contentRef}>
      <VirtualTable
        dataSource={types}
        columns={
          [
            {
              title: "Key",
              dataIndex: "key",
              sorter: (a,b) => a.key.localeCompare(b.key)
            },
            {
              title: "Name",
              dataIndex: "name",
              sorter: (a,b) => a.name.localeCompare(b.name)
            },
            {
              title: "Category",
              dataIndex: "category",
              defaultSortOrder: 'ascend',
              sorter: (a,b) => a.category.localeCompare(b.category)
            },
            {
              title: "Tag",
              dataIndex: "key",
              render: (key) => <ActivityTypeTag type={key} />
            },
            {
              title: "Color",
              dataIndex: "color",
              render: (color) => <div style={{backgroundColor: color, textAlign: 'center'}}>{color}</div>
            },
          ]
        }
        pagination={false}
        scroll={{y: height}}
      />
    </div>
  );
};

export default ActivityTypesTable;