import {
  timeDay,
  timeYears,
  timeSaturdays,
  timeSundays,
  timeMondays,
  timeTuesdays,
  timeWednesdays,
  timeThursdays,
  timeFridays
} from 'd3';

export const msPerSecond = 1000;
export const hoursPerDay = 24;
export const minutesPerHour = 60;
export const secondsPerMinute = 60;
export const daysPerWeek = 7;
export const secondsPerHour = secondsPerMinute * minutesPerHour;
export const msPerDay = (hoursPerDay * minutesPerHour * secondsPerMinute * msPerSecond);
export const msPerWeek = msPerDay * daysPerWeek;
export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];

export const getDaysStartingWith = (firstDayOfWeek: string) => {
  const startDayIndex = days.findIndex(d => d === firstDayOfWeek);
  return [...days.slice(startDayIndex,days.length),...days.slice(0,startDayIndex)];
}

export const yearsInRange = (from: Date,to: Date) => {
  const firstOfFrom = new Date(from.getFullYear(),0,1);
  return timeYears(firstOfFrom,to).map(d => d.getFullYear());
}

export const firstDayOfYear = (year: number, firstDayOfWeek?: string) => {
  const firstOfYear = new Date(year,0,1);
  const lastDayOfFirstWeek = new Date(year,0,8);
  switch(firstDayOfWeek) {
    case "Saturday":
      return timeSaturdays(firstOfYear,lastDayOfFirstWeek)[0];
    case "Sunday":
      return timeSundays(firstOfYear,lastDayOfFirstWeek)[0];
    case "Monday":
      return timeMondays(firstOfYear,lastDayOfFirstWeek)[0];
    case "Tuesday":
      return timeTuesdays(firstOfYear,lastDayOfFirstWeek)[0];
    case "Wednesday":
      return timeWednesdays(firstOfYear,lastDayOfFirstWeek)[0];
    case "Thursday":
      return timeThursdays(firstOfYear,lastDayOfFirstWeek)[0];
    case "Friday":
      return timeFridays(firstOfYear,lastDayOfFirstWeek)[0];
    default:
      return timeMondays(firstOfYear,lastDayOfFirstWeek)[0];
  }
}

export const weeksInYear = (year: number, firstDayOfWeek?: string) => {
  const firstOfYear = new Date(year,0,1);
  const lastOfYear = new Date(year,11,31);
  switch(firstDayOfWeek) {
    case "Saturday":
      return timeSaturdays(firstOfYear,lastOfYear);
    case "Sunday":
      return timeSundays(firstOfYear,lastOfYear);
    case "Monday":
      return timeMondays(firstOfYear,lastOfYear);
    case "Tuesday":
      return timeTuesdays(firstOfYear,lastOfYear);
    case "Wednesday":
      return timeWednesdays(firstOfYear,lastOfYear);
    case "Thursday":
      return timeThursdays(firstOfYear,lastOfYear);
    case "Friday":
      return timeFridays(firstOfYear,lastOfYear);
    default:
      return timeMondays(firstOfYear,lastOfYear);
  }
}

export const daysInYear = (year: number) => {
  const firstOfYear = new Date(year,0,1);
  const lastOfYear = new Date(year+1,0,1);
  return timeDay.range(firstOfYear,lastOfYear);
}

export const weekOfYear = (d: Date, firstDayOfWeek: string) => {
  const year = d.getFullYear();
  const firstDay = firstDayOfYear(year,firstDayOfWeek);
  const week = Math.ceil((d.getTime() - firstDay.getTime() + 1) / msPerWeek);
  return {
    year,
    week,
    yearWeek: week < 10 ? `${year}-0${week}` : `${year}-${week}`
  }
}