import React from 'react';
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { useDarkMode } from 'usehooks-ts';
import { ConfigProvider, theme } from 'antd';
import { AppMenu } from './components/AppMenu';
import { Router } from './components/Router';
import { useStyle } from './hooks/useStyle';
import './App.css';

const queryClient = new QueryClient();

function App() {
  const { isDarkMode } = useDarkMode();
  const style = useStyle();

  return (
    <div className="App" style={style} >
      <QueryClientProvider client={queryClient}>
        <ConfigProvider theme={isDarkMode ? { algorithm: theme.darkAlgorithm } : {}}>
          <AppMenu />
          <Router />
        </ConfigProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
