import React from 'react';
import { Navigate } from "react-router-dom";
import { useElementSize } from 'usehooks-ts';
import { 
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { useActivities } from '../hooks/useActivities';
import { useStyle } from '../hooks/useStyle';
import { useUrlParams } from '../hooks/useUrlParams';
import { lookupType } from '../services/ActivityTypes';
import { ActivityLocalized } from "../services/ActivityVizApi";
import { groupByAgg } from '../services/General';
import { months } from "../services/DateTimeUtils";
import { AggOptions, getAggOptions, getGroupAxisOptions, GroupAxisOptions } from '../services/GroupAxisOptions';
import { ChartSettings } from "./ChartSettings";

const groupActivities = (activities: Array<ActivityLocalized>, colorAxisOptions: GroupAxisOptions, aggOption: AggOptions) => {
  return groupByAgg(
    activities,
    (a) => {
      return {
        "color": colorAxisOptions.accessor(a),
      }
    },
    (arr) => {
      return {
        "agg": aggOption.accumulator(arr)
      }
    }
  );
}

export const GroupedPieChart = () => {
  const style = useStyle();
  const [ref, { height }] = useElementSize();
  const { user, activitiesLocalized, types, typesSpecific, years } = useActivities();
  const { getParam, setParams } = useUrlParams({
    colorAxis:"Type",
    agg:"Distance",
    types: "",
    year: "All",
    month: "All"
  });

  if(activitiesLocalized.length == 0) {
    // no user?  redirect to home
    return (<Navigate to="/" />)
  }
  let activitiesFiltered = activitiesLocalized;
  const filterTypes = getParam("types")!.split(",").filter(t => t.length > 0);
  if(filterTypes.length > 0) {
    activitiesFiltered = activitiesFiltered.filter(a => filterTypes.includes(a.type));
  }
  if(getParam("year") != "All") {
    activitiesFiltered = activitiesFiltered.filter(a => String(a.startDateLocal.getFullYear()) === getParam("year"));
  }
  if(getParam("month") != "All") {
    activitiesFiltered = activitiesFiltered.filter(a => months[a.startDateLocal.getMonth()] === getParam("month"));
  }

  const colorAxisOptions = getGroupAxisOptions(getParam("colorAxis")!,years,types, typesSpecific);
  const aggOptions = getAggOptions(getParam("agg")!,user!.unit);
  const data = groupActivities(activitiesFiltered, colorAxisOptions, aggOptions);

  return (
    <div className="content">
      <ChartSettings 
        getParam={getParam}
        setParams={setParams}
        unit={user!.unit}
        agg={true}
        colorAxis={["Year","Month","Week","Day","Type","Type Specific","Winter Season"]}
        filterTypes={types}
        filterYear={years}
        filterMonth={true}
      />
      <div className="chart" ref={ref}>
        <ResponsiveContainer height={height}>
          <PieChart>
            <Pie
              data={data}
              outerRadius={"100%"}
              cx="50%"
              cy="50%"
              fill="#8884d8"
              nameKey="color"
              dataKey="agg"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={lookupType(entry.color).color} />
              ))}
            </Pie>
            <Tooltip 
              contentStyle={style}
              itemStyle={style}
              //@ts-ignore
              formatter={(v: number) => `${aggOptions.formatter(v)} ${aggOptions.unit}`}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default GroupedPieChart;