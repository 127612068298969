import React, { lazy, Suspense } from 'react';
import {
  Routes, Route,
} from "react-router-dom";
import { Home } from "./Home";
import { Dashboard } from "./Dashboard";
import { ActivitiesTable } from "./ActivitiesTable";
import { YtdLineChart } from './YtdLineChart';
import { StravaAuthComplete } from "./StravaAuthComplete";
import { ActivityTypesTable } from './ActivityTypesTable';
import { ActivityScatterChart } from './ActivityScatterChart';
import { ActivityStreamsTable } from './ActivityStreamsTable';
const ActivityDetail = lazy(() => import('./ActivityDetail'));
const TimeHeatmap = lazy(() => import('./TimeHeatmap'));
const WeeklyChart = lazy(() => import('./WeeklyChart'));
const GroupedBarChart = lazy(() => import('./GroupedBarChart'));
const GroupedPieChart = lazy(() => import('./GroupedPieChart'));

export const routeKeys: Map<string, string> = new Map<string, string>(
  [
    ['/','home'],
    ['/dashboard','dashboard'],
    ['/activities/table','activitiesTable'],
    ['/chart/scatter','activitiesScatter'],
    ['/chart/ytd','ytdLine'],
    ['/chart/weekly','weeklyChart'],
    ['/chart/stacked','stacked'],
    ['/chart/pie','pieChart'],
    ['/heatmap/time','timeHeatmap'],
    ['/stravaAuthComplete','stravaAuthComplete']
  ]
);

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />}/>
      <Route path="/dashboard" element={<Dashboard />}/>
      <Route path="/activities/table" element={<ActivitiesTable />}/>
      <Route 
        path="/activities/:activityId" 
        element={
          <Suspense>
            <ActivityDetail />
          </Suspense>
        }/>
      <Route 
        path="/activities/:activityId/table"
        element={<ActivityStreamsTable />}
      />
      <Route 
        path="/chart/bar" 
        element={
          <Suspense>
            <GroupedBarChart />
          </Suspense>
        }
      />,
      <Route 
        path="/chart/pie" 
        element={
          <Suspense>
            <GroupedPieChart />
          </Suspense>
        }
      />,
      <Route path="/chart/scatter" element={<ActivityScatterChart />} />,
      <Route path="/chart/ytd" element={<YtdLineChart />} />,
      <Route 
        path="/chart/weekly" 
        element={
          <Suspense>
            <WeeklyChart />
          </Suspense>
        } 
      />
      <Route 
        path="/heatmap/time" 
        element={
          <Suspense>
            <TimeHeatmap />
          </Suspense>
        }
      />
      <Route path="/stravaAuthComplete" element={<StravaAuthComplete />}/>
      <Route path="/types" element={<ActivityTypesTable />} />
    </Routes>
  );
};

export default Router;
