import { useSearchParams } from "react-router-dom";

export const useUrlParams = (initialState: Record<string,any>) => {
  const [searchParams, setSearchParams] = useSearchParams(initialState);
  
  const getParam = (key: string) => {
    return searchParams.get(key);
  }
  const setParams = (params: Record<string,any>) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      ...params
    })
  }
  return {
    getParam,
    setParams
  }
}
