import { ActivityLocalized } from "../services/ActivityVizApi";
import { colors12, colors36 } from "./Colors";
import { formatCommas, localDistanceUnit } from "./Formatters";
import { days, getDaysStartingWith, weekOfYear, months } from "./DateTimeUtils";
import { sum } from '../services/General';

interface AxisValue {
  key: string;
  color: string;
}

export interface GroupAxisOptions {
  name: string,
  accessor: (activity: ActivityLocalized) => string,
  values: Array<AxisValue>,
}

const typeAxisOptions = (types: AxisValue[]) => {
  return {
    name: "Type",
    accessor: (activity: ActivityLocalized) => activity.type,
    values: types,
  }
}

const typeSpecificAxisOptions = (typesSpecific: AxisValue[]) => {
  return {
    name: "Type Specific",
    accessor: (activity: ActivityLocalized) => activity.typeSpecific,
    values: typesSpecific,
  }
}

const dayAxisOptions = {
  name: "Day",
  accessor: (activity: ActivityLocalized) => days[activity.startDate.getDay()],
  values: getDaysStartingWith("Monday").map((v: string,i: number) => {return {key:v, color:colors12[i]}}),
}

const weekAxisOptions = {
  name: "Week",
  accessor: (activity: ActivityLocalized) => String(weekOfYear(activity.startDate,"Monday").week),
  values: Array(53).fill(0).map((_,i) => String(i)).map((v,i) => {return {key:v, color:colors36[i%36]}}),
}

const monthAxisOptions = {
  name: "Month",
  accessor: (activity: ActivityLocalized) => months[activity.startDate.getMonth()],
  values: months.map((v: string,i: number) => {return {key:v, color:colors12[i]}}),
}

const yearAxisOptions = (years: number[]) => {
  return {
    name: "Year",
    accessor: (activity: ActivityLocalized) => String(activity.startDate.getFullYear()),
    values: years.map(y => String(y)).map((v,i) => {return {key:v, color:colors36[i%36]}}),
  }
}

const winterSeasonAxisOptions = (years: number[]) => {

  const lookupWinterSeason = (date: Date) => {
    if(date.getMonth() < 6) {
      return `${date.getFullYear()-1}-${date.getFullYear()}`;
    } else {
      return `${date.getFullYear()}-${date.getFullYear()+1}`;
    }
  }

  return {
    name: "Winter Season",
    accessor: (activity: ActivityLocalized) => lookupWinterSeason(activity.startDate),
    values: years.map(y => String(y)).map((v,i) => {return {key:`${v}-${Number(v)+1}`, color:colors36[i%36]}}),
  }
}

export const getGroupAxisOptions = (axis: string, years: number[] = [], types: AxisValue[] = [], typesSpecific: AxisValue[] = []) : GroupAxisOptions => {
  switch(axis) {
    case "Type":
      return typeAxisOptions(types);
    case "Type Specific":
      return typeSpecificAxisOptions(typesSpecific);
    case "Year":
      return yearAxisOptions(years);
    case "Winter Season":
      return winterSeasonAxisOptions(years);
    case "Month":
      return monthAxisOptions;
    case "Week":
      return weekAxisOptions;
    case "Day":
      return dayAxisOptions;
    default: 
      return monthAxisOptions;
  }
}

export interface AggOptions {
  name: string;
  accessor: (activity: ActivityLocalized) => number;
  accumulator: (activities: Array<ActivityLocalized>) => number;
  formatter: (val: number) => string;
  unit: string;
}

const countAggOptions = {
  name: "Count",
  accessor: (activity: ActivityLocalized) => 1,
  accumulator: (activities: Array<ActivityLocalized>) => activities.length,
  formatter: formatCommas,
  unit: "Activities"
};

const distanceAggOptions = (unit: string) => {
  return {
    name: "Distance",
    accessor: (activity: ActivityLocalized) => activity.distance,
    accumulator: (activities: Array<ActivityLocalized>) => activities.map(a => a.distance).reduce(sum,0),
    formatter: (v: number) => formatCommas(Number(v.toFixed(0))),
    unit: localDistanceUnit(unit,true)
  }
};

const elevationAggOptions = (unit: string) => {
  return {
    name: "Elevation",
    accessor: (activity: ActivityLocalized) => activity.elevationGain,
    accumulator: (activities: Array<ActivityLocalized>) => activities.map(a => a.elevationGain).reduce(sum,0),
    formatter: (v: number) => `${(v/1000).toFixed(1)}k`,
    unit: localDistanceUnit(unit,false)
  }
};

const movingTimeAggOptions = {
  name: "Moving Time",
  accessor: (activity: ActivityLocalized) => activity.movingTime/3600,
  accumulator: (activities: Array<ActivityLocalized>) => activities.map(a => a.movingTime).reduce(sum,0)/3600,
  formatter: (v: number) => formatCommas(Number(v.toFixed(1))),
  unit: "Hours"
}

export const getAggOptions = (agg: string, unit: string) : AggOptions => {
  switch(agg) {
    case "Count":
      return countAggOptions;
    case "Moving Time":
      return movingTimeAggOptions;
    case "Distance":
      return distanceAggOptions(unit);
    case "Elevation":
      return elevationAggOptions(unit);
    default:
      return countAggOptions;
  }
}