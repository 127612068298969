import { Activity } from "./ActivityVizApi";
import { minutesPerHour, secondsPerHour, secondsPerMinute } from "./DateTimeUtils";

const metersPerKm = 1000;
const metersPerFoot = 0.3048;
const metersPerMile = 1609.34;

export const paceTypes = ["Run","Walk","Hike","NordicSki"];

export const localizeTemp = (celsius: number, unit: string): number => {
  switch(unit) {
    case "metric":
      return celsius;

    case "imperial":
      return (celsius * 9 / 5) + 32;

    default:
      return 0;
  }
}

export const localTempUnit = (unit: string): string => {
  switch(unit) {
    case "metric":
      return "°C";

    case "imperial":
      return "°F";

    default:
      return "";
  } 
}

export const localizeSpeed = (metersPerSecond: number, unit: string): number => {
  switch(unit) {
    case "metric":
      return metersPerSecond * secondsPerHour / metersPerKm;

    case "imperial":
      return metersPerSecond * secondsPerHour / metersPerMile;

    default:
      return 0;
  }
}

export const localSpeedUnit = (unit: string): string => {
  switch(unit) {
    case "metric":
      return "km/h";

    case "imperial":
      return "mph";

    default:
      return "";
  } 
}

export const localizePace = (metersPerSecond: number, unit: string): number => {
  switch(unit) {
    case "metric":
      return metersPerKm / metersPerSecond;

    case "imperial":
      return metersPerMile / metersPerSecond;

    default:
      return 0;
  }
}

export const localPaceUnit = (unit: string): string => {
  switch(unit) {
    case "metric":
      return "/km";

    case "imperial":
      return "/mile";

    default:
      return "";
  } 
}

export const localizeDistance = (meters: number, unit: string, large: boolean): number => {
  switch(unit) {
    case "metric":
      if(large) {
        return meters / metersPerKm;
      } else {
        return meters;
      }

    case "imperial":
      if(large) {
        return meters / metersPerMile;
      } else {
        return meters / metersPerFoot;
      }

    default:
      return 0;
  }
}

export const localDistanceUnit = (unit: string, large: boolean) => {
  switch(unit) {
    case "metric":
      if(large) {
        return "km";
      } else {
        return "m"
      }

    case "imperial":
      if(large) {
        return "miles";
      } else {
        return "feet";
      }

    default:
      return "";
  }
}
  
export const formatLocalizedDistance = (meters: number, unit: string, large: boolean): any => {
  const localDistance = localizeDistance(meters, unit, large);
  if(large) {
    return Math.round(localDistance * 100) / 100;
  } else {
    return formatCommas(Math.round(localDistance));
  }
}

export const formatCommas = (num: number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatSeconds = (totalSeconds: number) : string => {
  const pad = (sec : number) => {
    if(sec < 10)
      return `0${sec}`;
    else
      return `${sec}`;
  }
  const hours = Math.floor(totalSeconds / secondsPerHour);
  const minutes = Math.floor((totalSeconds / secondsPerMinute) % minutesPerHour);
  const seconds = Math.floor((totalSeconds) % secondsPerMinute);
  if(hours == 0 && minutes < 10)
    return `${minutes}:${pad(seconds)}`;
  else if(hours == 0)
    return `${pad(minutes)}:${pad(seconds)}`;
  else
    return `${hours}:${pad(minutes)}:${pad(seconds)}`;
}

export const datesBetween = (fromDate: Date, toDate: Date, intervalMins: number): Date[] => {
  const intervalMs = intervalMins * 60 * 1000;
  let date = new Date(Math.ceil(fromDate.getTime() / intervalMs) * intervalMs);
  const dates: Date[] = [];
  while(date.getTime() < toDate.getTime()) {
    dates.push(date);
    date = new Date(date.getTime() + intervalMs);
  }
  return dates;
}

export const yearsBetween = (fromDate: Date, toDate: Date): Date[] => {
  let date = new Date(fromDate.getFullYear()+1,0,1);
  const dates: Date[] = [];
  while(date.getTime() < toDate.getTime()) {
    dates.push(date);
    date = new Date(date.getFullYear()+1,0,1);
  }
  return dates;
}

export const activityTicks = (activity: Activity) => {
  const startDate = new Date(activity.startDate);
  const endDate = new Date(startDate.getTime() + (activity.elapsedTime * 1000));
  const mins = activity.elapsedTime / 60;
  let factor = 15;
  while(mins / factor > 8) {
    factor *= 2;
  }
  const tickDates = datesBetween(startDate,endDate,factor);
  return tickDates;
}