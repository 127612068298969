import axios, { AxiosResponse} from "axios";
import { localizeDistance, localizePace, localizeSpeed } from "./Formatters";
import { urlEncode } from "./General";

const stravaClientId = 15183;
const stravaScope = "profile:read_all,activity:read_all";

export type ApiUser = {
  firstName: string;
  lastName: string;
  username: string;
  city: string;
  state: string;
  country: string;
  stravaUserId: number;
  stravaAccessToken: string;
  unit: string;
};

export type Activity = {
  name: string;
  stravaActivityId: number;
  stravaUserId: number;
  type: string;
  typeSpecific: string;
  startDate: string;
  startDateLocal: string;
  distance: number;
  elapsedTime: number;
  movingTime: number;
  elevationGain: number;
  avgHeartrate?: number;
  avgCadence?: number;
  avgWatts?: number;
  maxSpeed?: number;
  sufferScore?: number;
}

export type ActivityLocalized = {
  name: string;
  stravaActivityId: number;
  stravaUserId: number;
  type: string;
  typeSpecific: string;
  startDate: Date;
  startDateLocal: Date;
  distance: number;
  elapsedTime: number;
  movingTime: number;
  elevationGain: number;
  avgHeartrate?: number;
  avgCadence?: number;
  avgWatts?: number;
  maxSpeed?: number;
  maxPace?: number;
  sufferScore?: number;
  speed: number;
  pace: number;
}

type StreamData = {
  data: any[];
  original_size: number;
  resolution: string;
  series_type: string;
}

export type ActivityStreams = {
  stravaUserId: number;
  stravaActivityId: number;
  altitude?: StreamData;
  cadence?: StreamData;
  velocity_smooth?: StreamData;
  distance?: StreamData;
  heartrate?: StreamData;
  latlng?: StreamData;
  temp?: StreamData;
  time?: StreamData;
  watts?: StreamData;
  grade_smooth?: StreamData;
}

export const localizeActivity = (a: Activity, unit: string): ActivityLocalized => {
  return {
    ...a,
    'startDate': new Date(a.startDate),
    'startDateLocal': new Date(a.startDateLocal),
    'distance': localizeDistance(a.distance,unit,true),
    'elevationGain': localizeDistance(a.elevationGain,unit,false),
    'speed': localizeSpeed(a.distance / a.movingTime, unit),
    'maxSpeed': a.maxSpeed ? localizeSpeed(a.maxSpeed, unit) : undefined,
    'pace': localizePace(a.distance / a.movingTime, unit),
    'maxPace': a.maxSpeed ? localizeSpeed(1/a.maxSpeed, unit) : undefined
  }
}

export const getStravaAuthUrl = (): string => {
  const argStr = urlEncode({
    client_id: stravaClientId,
    response_type: "code",
    redirect_uri: `${window.location.hostname == "localhost" ? "http" : "https"}://${window.location.host}/stravaAuthComplete`,
    scope: stravaScope,
    //approval_prompt: "auto",
  });
  return `https://www.strava.com/oauth/authorize?${argStr}`;
}

export const completeStravaAuth = (code:string) : Promise<ApiUser> => {
  const argStr = urlEncode({
    code,
  });
  return axios.get<ApiUser>(`https://api.activityviz.com/auth/complete?${argStr}`)
    .then((res) => res.data);
}

export const getUser = (userId: number): Promise<ApiUser> => {
  return axios.get<ApiUser>(`https://api.activityviz.com/user/${userId}`)
    .then((res) => res.data);
}

export const getActivities = (userId: number): Promise<Array<Activity>> => {
  return axios.get<Array<Activity>>(`https://api.activityviz.com/user/${userId}/activities`)
    .then((res) => res.data);
}

export const getActivity = (userId: number, activityId: number): Promise<Activity> => {
  return axios.get<Activity>(`https://api.activityviz.com/user/${userId}/activities/${activityId}`)
    .then((res) => res.data);;
}

export const fetchActivities = (userId: number, page = 1, pageSize = 200): Promise<Array<Activity>> => {
  const argStr = urlEncode({
    page_size: pageSize,
    page,
  });
  return axios.get<Array<Activity>>(`https://api.activityviz.com/user/${userId}/activities/fetch?${argStr}`)
    .then((res) => res.data);
}

export const fetchThenGetActivities = (userId: number, page = 1, pageSize = 200): Promise<Array<Activity>> => {
  const argStr = urlEncode({
    page_size: pageSize,
    page,
  });
  return axios.get<Array<Activity>>(`https://api.activityviz.com/user/${userId}/activities/fetch?${argStr}`)
    .then(() => getActivities(userId));
}

export const fetchAllActivities = (userId: number): Promise<undefined> => {
  return axios.get<undefined>(`https://api.activityviz.com/user/${userId}/activities/fetchAll`)
    .then((res) => res.data);
}

export const getActivityStreams = (userId: number, activityId: number): Promise<ActivityStreams> => {
  return axios.get<ActivityStreams>(`https://api.activityviz.com/user/${userId}/activities/${activityId}/streams`)
    .then((res) => res.data);
}