import React, { useRef } from "react";
import { useElementSize } from "usehooks-ts";
import { Link, useNavigate } from "react-router-dom";
import { Button, Input, Table } from 'antd';
import type { InputRef } from 'antd';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import { SearchOutlined } from "@ant-design/icons";
import { ActivityTypeTag } from "./ActivityTypeTag";
import { useActivities } from "../hooks/useActivities";
import { useUrlParams } from "../hooks/useUrlParams";
import { lookupType } from "../services/ActivityTypes";
import { groupBy } from "../services/General";
import { formatSeconds } from "../services/Formatters";
import { VirtualTable } from "./VirtualTable";

export const ActivitiesTable = () => {
  const [contentRef, {height}] = useElementSize();
  const { activitiesLocalized } = useActivities();
  const { getParam, setParams } = useUrlParams({
    name: "",
    types: ""
  });
  const navigate = useNavigate();
  const searchInputRef = useRef<InputRef>(null);

  if(activitiesLocalized.length == 0) {
    navigate("/");
  }

  const typesSet = Array.from(new Set(activitiesLocalized.map(a => a.type))).map(lookupType);
  const typesGrouped = groupBy(typesSet,(t) => {return {"category":t.category}});
  const typeSelectOptions = Array.from(typesGrouped.entries())
    .map(([g,vals]) => {
      return {
        text: String(JSON.parse(g).category),
        value: String(JSON.parse(g).category),
        children: vals.map(t => {
          return {
            text: <ActivityTypeTag type={t.key} />,
            value: String(t.key)
          }
        })
      }
    });

  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void,) => {
    confirm();
    setParams({
      name: selectedKeys[0]
    });
  };

  const handleReset = (clearFilters: () => void) => {
    setParams({
      name: ""
    });
  };

  return (
    <div className="content" ref={contentRef}>
      <VirtualTable
      //<Table 
        dataSource={activitiesLocalized} 
        onChange={(pagination, filters, sorter) => {
          setParams({
            "types": Array(filters["type"]).join(",")
          });
        }}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 300,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
              <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                  ref={searchInputRef}
                  placeholder={`Search activity name`}
                  value={selectedKeys[0]}
                  onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                  onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
                  style={{ marginBottom: 8, display: 'block' }}
                />
                <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys as string[], confirm)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Search
                </Button>
                <Button
                  onClick={() => clearFilters && handleReset(clearFilters)}
                  size="small"
                  style={{ width: 90 }}
                >
                  Reset
                </Button>
              </div>
            ),
            filteredValue: getParam("name")!.length > 0 ? [getParam("name")!] : undefined,
            filterIcon: (filtered: boolean) => (
              <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
            onFilter: (value, record) =>
              record['name']
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
            onFilterDropdownOpenChange: (visible) => {
              console.log(searchInputRef.current);
              if (visible) {
                searchInputRef.current?.select();
              }
            },
            render: (_,activity) => <Link to={`/activities/${activity.stravaActivityId}`}>{activity.name}</Link>
          },
          {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: 150,
            filters: typeSelectOptions,
            filterMode: 'tree',
            filteredValue: getParam("types")!.length > 0 ? getParam("types")!.split(",") : undefined,
            align: 'center' as 'center',
            onFilter: (filterValue, record) => record.type === filterValue,
            render: (value) => <ActivityTypeTag type={value} />
          },
          {
            title: 'Date',
            dataIndex: 'startDateLocal',
            key: 'startDateLocal',
            width: 100,
            sorter: (a,b) => a.startDate.getTime() - b.startDate.getTime(),
            defaultSortOrder: 'descend',
            render: (value) => value.toLocaleDateString(),
          },
          {
            title: 'Time',
            dataIndex: 'startDate',
            key: 'startTimeLocal',
            width: 120,
            sorter: (a,b) => a.startDate.getTime() - b.startDate.getTime(),
            defaultSortOrder: 'descend',
            render: (value) => value.toLocaleTimeString('en-US'),
          },
          {
            title: 'Duration',
            dataIndex: 'elapsedTime',
            key: 'elapsedTime',
            width: 100,
            sorter: (a,b) => a.elapsedTime - b.elapsedTime,
            render: (value) => formatSeconds(value),
          },
          {
            title: 'Distance',
            dataIndex: 'distance',
            key: 'distance',
            width: 100,
            sorter: (a,b) => a.distance - b.distance,
            render: (v) => v.toFixed(1)
          },
          {
            title: 'Elevation Gain',
            dataIndex: 'elevationGain',
            key: 'elevationGain',
            width: 100,
            sorter: (a,b) => a.elevationGain - b.elevationGain,
            render: (v) => v.toFixed(0)
          },
          {
            title: 'Avg. Heart Rate',
            dataIndex: 'avgHeartrate',
            key: 'avgHeartrate',
            width: 100,
            render: (v) => Number(v).toFixed(0)
          },
          {
            title: 'Suffer Score',
            dataIndex: 'sufferScore',
            key: 'sufferScore',
            width: 100,
            sorter: (a,b) => Number(a.sufferScore) - Number(b.sufferScore),
            render: (v) => Number(v).toFixed(0)
          },
        ]}
        pagination={false}
        scroll={{ y: height }}
      />
    </div>
  );
};

export default ActivitiesTable;