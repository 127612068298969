import { useDarkMode } from 'usehooks-ts';

type Style = {
  backgroundColor: string;
  color: string;
  fill: string;
}

const lightColor = "#fff";
const darkColor = "#000";

export const useStyle = () : Style => {
  const { isDarkMode } = useDarkMode();

  if(isDarkMode) {
    return {
      backgroundColor: darkColor,
      color: lightColor,
      fill: lightColor
    }
  } else {
    return {
      backgroundColor: lightColor,
      color: darkColor,
      fill: darkColor
    }
  }
}