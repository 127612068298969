import { Activity, ActivityLocalized } from "./ActivityVizApi";

export const urlEncode = (obj: Object) => {
  let str = [];
  const entries = Object.entries(obj);
  for(let i=0; i<entries.length; i++) {
    let e = entries[i];
    str.push(encodeURIComponent(e[0]) + "=" + encodeURIComponent(e[1]));
  }
  return str.join("&");
}

export const mapToObject = (m: Map<string,any>): Record<string,any> => {
  return Array.from(m)
    .reduce((obj, [key, value]) => (Object.assign(obj, { [key]: value })), {});
}

export function groupBy<Type>(arr: Array<Type>, fn: (a: Type) => Object): Map<string,Array<Type>> {
  return arr
  .reduce((groups,val) => {
    const groupVal = JSON.stringify(fn(val));
    if(groups.has(groupVal)) {
      groups.get(groupVal)!.push(val);
    } else {
      groups.set(groupVal,[val]);
    }
    return groups;
  },new Map<string,Array<Type>>());
}

export function agg<Type>(groups: Map<string,Array<Type>>, fn: (a: Array<Type>) => Record<string,any>): Array<Record<string,any>> {
  return Array.from(groups)
    .map(val => {
      return {
        ...JSON.parse(val[0]),
        ...fn(val[1]),
      };
    });
}

export function groupByAgg<Type>(arr: Array<Type>, groupFn: (a: Type) => Record<string,any>, aggFn: (a: Array<Type>) => Record<string,any>): Array<Record<string,any>> {
  return agg(groupBy(arr, groupFn), aggFn);
}

export const max = (acc: number, val: number) => {
  return val > acc ? val : acc;
}

export const sum = (acc: number, val: number) => {
  return acc += val;
}

type timeSpread = {
  timeBlock: string;
  seconds: number;
}

export function spreadByHour<Type>(startTime: Date, endTime: Date, groupKeys: Type) : Array<timeSpread & Type> {
  const timeSpreads: Array<timeSpread & Type> = [];
  let currentHour = new Date(startTime.getTime());
  currentHour.setMinutes(0);
  currentHour.setSeconds(0);
  while(currentHour.getTime() < endTime.getTime()) {
    let nextHour =  new Date(currentHour.getTime() + 3600000);
    let secondsInHour = (Math.min(nextHour.getTime(),endTime.getTime()) - Math.max(startTime.getTime(),currentHour.getTime()))/1000;
    timeSpreads.push({
      ...groupKeys,
      timeBlock: `${currentHour.getUTCHours()}:00`,
      seconds: secondsInHour,
    })
    currentHour = nextHour;
  }
  return timeSpreads;
}

export function spreadActivityByHour<Type>(activity: ActivityLocalized, keyFunc: (activity: ActivityLocalized) => Type) : Array<timeSpread & Type> {
  const startDate = activity.startDateLocal;
  const endDate = new Date(startDate.getTime() + (activity.elapsedTime * 1000));
  return spreadByHour(startDate,endDate, keyFunc(activity));
}